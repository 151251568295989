import Vue from "vue";

export default {
    version: "1.0",
    BASE_URL: 'https://5dollarpos.brk-dev.ro/api/index.php',

    set_token: function (newToken) {
        window.localStorage.setItem("5dollarcoffer-token", newToken);
        Vue.http.headers.common["Token"] = newToken;
    },

    get_token: function () {
        return window.localStorage.getItem("5dollarcoffer-token");
    },

    set_email: function (email) {
        window.localStorage.setItem('5dollarcoffer-user_email', JSON.stringify(email))
    },

    get_email: function () {
        var email = window.localStorage.getItem("5dollarcoffer-user_email")
        return JSON.parse(email)
    },

    set_drepturi: function (drepturi) {
        window.localStorage.setItem('5dollarcoffer-drepturi_user', JSON.stringify(drepturi));
    },

    get_drepturi: function () {
        var drepturi = window.localStorage.getItem('5dollarcoffer-drepturi_user');
        return JSON.parse(drepturi);
    },
    set_user_type: function (user_type) {
        window.localStorage.setItem('5dollarcoffer-user_type', user_type);
    },

    get_user_type: function () {
        return window.localStorage.getItem('5dollarcoffer-user_type');
    },

    is_logged_in: function () {
        var token = this.get_token();
        return token !== "";
    },

    logout: function () {
        this.set_token('');
        this.set_drepturi('');
        this.set_email("")
    },

    verify_response: function (response) {
        if (response.body.NotLogged) {
            return false;
        }
        return true;
    },

    verify_login_and_redirect: function (vueInstance) {
        if (!this.is_logged_in()) {
            vueInstance.$router.push("/");
        }
    },
};
