import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    routes: [
        { path: '/', name: 'home', component: () => import('./pages/Login.vue') },
        { path: '/dashboard', name: 'dashboard', component: () => import('./pages/Dashboard.vue') },
        { path: '/categorii-drepturi', name: 'categorii-drepturi', component: () => import('./pages/categorii-drepturi/Categorii-drepturi_list.vue') },
        { path: '/drepturi', name: 'drepturi', component: () => import('./pages/drepturi/Drepturi_list.vue') },
        { path: '/grupuri-utilizatori', name: 'grupuri-utilizatori', component: () => import('./pages/grupuri-utilizatori/Grupuri-utilizatori_list.vue') },
        { path: '/utilizatori', name: 'utilizatori', component: () => import('./pages/utilizatori/Utilizatori_list.vue') },
        { path: '/mese', name: 'mese', component: () => import('./pages/mese/Mese_list.vue') },
        { path: '/category', name: 'category', component: () => import('./pages/category/Category_list.vue') },
        { path: '/products', name: 'products', component: () => import('./pages/products/Products_list.vue') },
        { path: '/methods_of_preparation', name: 'methods_of_preparation', component: () => import( './pages/methods_of_preparation/Methods_of_preparation_list.vue') },
        { path: '/extra', name: 'extra', component: () => import('./pages/extra/Extra_list.vue') },
        { path: '/settings', name: 'settings', component: () => import('./pages/settings/Settings_list.vue') },
        { path: '/orders', name: 'orders', component: () => import('./pages/orders/Orders_list.vue') },
        { path: '/app/tables', name: 'tables', component: () => import('./pages/frontend/tables/Table.vue') },
        { path: '/app/order/:id', name: 'order', component: () => import('./pages/frontend/order/Order.vue') },
        {path: '/app/payment/:id', name: 'payment', component: () => import('./pages/frontend/payment/Payment.vue')}
    ]
})
